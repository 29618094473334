import { IComputerPrice, ICreateBooking } from '@/types/interface';
import { apiClient } from './apiService';

export const partnerService = {
  computePrice(data: IComputerPrice) {
    return apiClient.request({
      method: 'POST',
      url: '/partner/compute_price',
      data,
    });
  },

  getPromotionDetail(code: string) {
    return apiClient.request({
      method: 'GET',
      url: `/partner/find_by_code_promotion/${code}`,
    });
  },

  getPhoneDetail(data: { dialCode: string; phone: string }) {
    return apiClient.request({
      method: 'POST',
      url: `/mobileCustomer/find_by_phone`,
      data,
    });
  },

  createBooking(data: ICreateBooking) {
    return apiClient.request({
      method: 'POST',
      url: '/partner/create_data',
      data,
    });
  },

  getStatistic(from: Date, to: Date) {
    return apiClient.request({
      method: 'POST',
      url: `/partner/dashboard`,
      data: {
        where: {
          isDeleted: false,
          scheduleDate: [from, to],
          status: ['COMPLETE'],
        },
        relations: ['driver', 'car', 'passenger', 'shift'],
        skip: 0,
        take: 999,
      },
    });
  },

  getHistory(scheduleDate: Date[], status: string[], skip: number, take = 12, collaboratorEmployeeId?: string | undefined) {
    return apiClient.request({
      method: 'POST',
      url: `/partner/orders-list`,
      data: {
        where: {
          isDeleted: false,
          scheduleDate,
          status,
          collaboratorEmployeeId
        },
        relations: ['driver', 'car', 'passenger', 'shift'],
        order: {
          scheduleDate: 'DESC',
        },
        skip: (skip - 1) * take,
        take,
      },
    });
  },

  getListNotification(skip: number, take = 10, where?: any) {
    return apiClient.request({
      method: 'POST',
      url: `/partner/notify/list`,
      data: {
        order: {
          createdAt: 'DESC',
        },
        relations: ['order'],
        skip: (skip - 1) * take,
        take,
        where,
      },
    });
  },

  readNotification(id: string) {
    return apiClient.request({
      method: 'POST',
      url: `/notify/comfirm`,
      data: {
        id,
      },
    });
  },

  getDetail(id: string) {
    return apiClient.request({
      method: 'POST',
      url: `/partner/orders/detail`,
      data: {
        id,
      },
    });
  },
};
